/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    const currentPosition = getSavedScrollPosition(location);
  
    // Using setTimeout to defer scrollTo until after the render cycle
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);
  
    return false;  // Prevent Gatsby's default scroll behavior
  };
// You can delete this file if you're not using it
